<template>
    <div class="page-body">
        <div class="table-body">
            <div class="table-header">
                <div class="handle-box">
                    <div class="search-input">
                        <table style="width: 100%;">
                            <tr>
                                <td style="width: 80px;">
                                    合同编号:
                                </td>
                                <td>
                                    <el-input v-model="searchItem.contractNum" style="width: 100%;" size="small"
                                              placeholder="请输入合同编号"
                                    ></el-input>
                                </td>
                                <td style="width: 80px;">
                                    客户名称:
                                </td>
                                <td>
                                    <el-input v-model="searchItem.companyName" style="width: 100%;" size="small"
                                              placeholder="请输入客户名称"
                                    ></el-input>
                                </td>
                                <td style="width: 80px;">
                                    合同名称:
                                </td>
                                <td>
                                    <el-input v-model="searchItem.contractName" style="width: 100%;" size="small"
                                              placeholder="请输入合同名称"
                                    ></el-input>
                                </td>
                                <td style="text-align: center;width: 310px">
                                    <el-date-picker
                                        style="margin:0 15px"
                                        v-model="time"
                                        type="daterange"
                                        align="right"
                                        unlink-panels
                                        size="small"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                        format="yyyy-MM-dd"
                                        value-format="yyyy-MM-dd"
                                        :default-time="['00:00:00', '23:59:59']"
                                    >
                                </el-date-picker>
                                    <el-button type="primary" style="margin:0 15px" icon="el-icon-search" size="small" @click="searchBtu">搜索
                                    </el-button>
                                    <el-button type="primary" style="margin:0 15px" icon="el-icon-refresh" size="small" @click="reset">重置
                                    </el-button>
                                    <el-button type="primary" icon="el-icon-download" size="small" @click="exportExcel">
                                        导出Excel表格
                                    </el-button>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div class="table-info">
                <div class="table-button">
                    <div>
                        <el-table
                                :data="tableData"
                                :max-height="tableHeight"
                                style="width: 100%;height: 100%;"
                                :border="true"
                                :header-cell-style="{ background: '#EBEEF8',height: '45px', 'text-align':'center' }"
                                :cell-style="{padding:'0',height: lineHeight}"
                                fit>
                            <el-table-column type="index"  label="序号" width="50" align="center"></el-table-column>
                            <el-table-column prop="companyName"  label="客户名称" width="180"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="contractNum"  label="合同号" width="180"
                                             align="center" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="contractName"  label="合同名称" width="280"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="outTime" label="出库日期"  width="120" :formatter="dateFormat" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column label="出库方式"  width="90" :formatter="dateFormat" align="center"
                                             show-overflow-tooltip>
                                <template slot-scope="scope" >
                                    <div v-if="scope.row.applyType == 0">
                                        <span>测试</span>
                                    </div>
                                    <div v-else>
										<span v-if="scope.row.contractType == 0">销售</span>
										<span v-else-if="scope.row.contractType == 1">租赁</span>
										<span v-else-if="scope.row.contractType == 2">部件买卖</span>
										<span v-else-if="scope.row.contractType == 3">售后</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="quality" label="数量" width="60"
                                             align="center" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="listNum"  label="物料清单编号" width="180" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="costAmount"  label="金额" width="180" align="right"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="costAmount"  label="材料成本合计" width="140" align="right"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="contractReceivables" label="备注"
                                             show-overflow-tooltip></el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="table-data">
                    <Pagination :pageSize="option.pageSize" :pageTotal="totalPage" :pageCurrent.sync="option.pageIndex"
                                :pageList="pageList" @handlePageChange="pageChange" @handleSizeChange="pageSizeChange"/>
                </div>
            </div>
        </div>
        <div class="export-report">
            <el-dialog
                    :title="exportTitle"
                    :visible.sync="exportExcelDialog"
                    width="70%"
                    :close-on-click-modal="false"
                    center>
                <el-table
                        :data="exportData"
                        max-height="400px"
                        size="small"
                        row-class-name="row"
                        cell-class-name="column"
                        border
                        :highlight-current-row="true"
                        fit>
                    <el-table-column
                            v-for="(item, index) in exportList"
                            :key="index"
                            :prop="item.prop"
                            :label="item.label" show-overflow-tooltip>
                    </el-table-column>
                </el-table>
                <el-button type="primary" size="small" style="margin-left: 48%;margin-top: 1%"
                           @click.native="exportExcelDialog=false">
                    <download-excel
                            class="export-excel-wrapper"
                            :data="json_data"
                            :fields="json_fields"
                            :name="fileName">
                        <!-- 上面可以自定义自己的样式，还可以引用其他组件button -->
                        导出数据
                    </download-excel>
                </el-button>
            </el-dialog>
        </div>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                pageList: [16, 20, 50, 100],
                tableHeight: '',
                fileName: '',
                option: {
                    pageSize: 16,
                    pageIndex: 1,
                },
                totalPage: 10,
                fields: [],
                values: [],
                json_fields: {},
                json_data: [],
                json_meta: [
                    [
                        {
                            " key ": " charset ",
                            " value ": " utf- 8 "
                        }
                    ]
                ],
                lineHeight: '41px',
                searchItem: {
                    contractNum: '',
                    companyName: '',
                    contractName: '',
                    pageSize: 16,
                    pageIndex: 1,
                    startDate:'',
                    endDate:'',
                },
                exportExcelDialog: false,
                inputWidth: '',
                exportList: [
                    {label: "客户名称", prop: "companyName"},
                    {label: "合同号", prop: "contractNum"},
                    {label: "合同名称", prop: "contractName"},
                    {label: "出库日期", prop: "outTime"},
                    {label: "出库方式", prop: "outType"},
                    {label: "发货通知单号", prop: "outListNum"},
                    {label: "数量", prop: "quality"},
                    {label: "物料清单编号", prop: "listNum"},
                    {label: "金额", prop: "costAmount"},
                    {label: "材料成本合计", prop: "costAmount"},
                    {label: "备注", prop: "remark"},
                ],
                tableData: [],
                exportData: [],
                arr: [],
                exportTitle: '',
                time:[],
            }
        },
        watch:{
            time:function(n,o){
                if(!n){
                    this.searchItem.startDate = '';
                    this.searchItem.endDate = '';
                }else{
                    this.searchItem.startDate = this.time[0];
                    this.searchItem.endDate = this.time[1];
                }
            }
        },
        methods: {
            pageChange(option) {
                this.searchItem.pageIndex = option;
                this.option.pageIndex = option;
                this.searchItem.pageSize = this.option.pageSize;
                this.$api.getRevenueReport(this.searchItem).then(res => {
                    if (res.code == 200){
                        for (var item of res.data.content) {
                            item.quality = 1;
                            item.costAmount = item.costAmount==0?0:(item.costAmount/100).toFixed(2)
                        }
                        this.tableData = res.data.content;
                        this.totalPage = res.data.totalElements;
                    }
                })
            },
            pageSizeChange(val) {
                this.searchItem.pageSize = val;
                this.$api.getRevenueReport(this.searchItem).then(res => {
                    if (res.code == 200){
                        for (var item of res.data.content) {
                            item.quality = 1;
                            item.costAmount = item.costAmount==0?0:(item.costAmount/100).toFixed(2)
                        }
                    }
                })
            },
            searchBtu() {
                this.pageChange(1);
            },
            reset() {
                this.searchItem = {
                    contractNum: '',
                    companyName: '',
                    contractName: '',
                    pageSize: 16,
                    pageIndex: 1,
                    startDate:'',
                    endDate:'',
                }
                this.time=[];
                this.pageChange(1);
            },
            exportExcel() {
                var date = new Date();
                var year = date.getFullYear();
                var month = (date.getMonth() + 1) < 10 ? 0 + '' + (date.getMonth() + 1) : ((date.getMonth() + 1))
                var date = date.getDate() < 10 ? 0 + '' + date.getDate() : (date.getDate())
                this.fileName = "收入成本报表" + year + month + date;
                this.exportTitle = "收入成本报表" + year + month + date;
                this.exportData = [];
                this.exportExcelDialog = true;
                this.searchItem.pageSize = 10000;
                this.$api.getRevenueReport(this.searchItem).then(res => {
                    if (res.code == 200) {
                        for (var tableItem of res.data.content) {
                            tableItem.quality = 1;
                            tableItem.costAmount = tableItem.costAmount==0?0:(tableItem.costAmount/100).toFixed(2)
                            tableItem.outType = tableItem.applyId == 0?'测试':(tableItem.contractType == 0?'销售':'租赁')
                        }
                        this.exportData = res.data.content
                        this.json_data = res.data.content
                    }
                })
            },
            dateFormat(row, column) {
                let data = row[column.property]
                let dt = new Date(data)
                var year = dt.getFullYear();
                var month = (dt.getMonth() + 1) < 10 ? 0 + '' + (dt.getMonth() + 1) : ((dt.getMonth() + 1))
                var date = dt.getDate() < 10 ? 0 + '' + dt.getDate() : (dt.getDate())
                return year + '-' + month + '-' + date
            },
        },
        created() {
            this.pageChange();
            this.tableHeight = (window.innerHeight) * 0.76
            this.inputWidth = document.body.scrollWidth * 0.25 + 'px';
            this.exportList.map((item, i) => {
                this.json_fields[item.label] = {
                    field: item.prop,
                    callback: value => {
                        return "" + value;
                    }
                }
            });
        }
    }
</script>

<style scoped>
    .page-body {
        background-color: #FFFFFF;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .table-body {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .table-header {
        height: 50px;
        background-color: #FFFFFF;
    }

    .handle-box {
        padding: 10px;
        margin-top: 10px;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .table-info {
        height: 100%;
        margin-top: 8px;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
    }

    .table-button {
        flex: 1;
        margin: 0 1%;
        margin-top: 10px;
    }

    .table-data {
        height: 6%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .span-text {
        width: 80px;
        padding-top: 5px;
        text-align: center;
    }

    .search-input {
        display: flex;
    }

    td {
        white-space: nowrap; /*控制单行显示*/
        overflow: hidden; /*超出隐藏*/
        text-overflow: ellipsis; /*隐藏的字符用省略号表示*/
    }
</style>